import { PriceType, Product } from "@bees-grow-shared/services";
import { faker } from "@faker-js/faker";

export function makeFakeProduct(override: Partial<Product> = {}) {
  const probability = 0.7;

  const quantityInfo = {
    suggested: faker.helpers.maybe(() => faker.number.int({ max: 2000000 }), {
      probability,
    }),
    stock: faker.helpers.maybe(() => faker.number.int(), { probability }),
    selectionLimit: faker.helpers.maybe(() => faker.number.int(), {
      probability,
    }),
    maxVariant: faker.helpers.maybe(() => faker.number.int(), { probability }),
    selectionMultiplier: faker.helpers.maybe(() => faker.number.int(), {
      probability,
    }),
    discountLimit: faker.helpers.maybe(() => faker.number.int(), {
      probability,
    }),
    minimum: faker.helpers.maybe(() => faker.number.int(), { probability }),
    selected: faker.helpers.maybe(() => faker.number.int(), { probability }),
  };

  const freeGoods = [
    {
      platformId: faker.string.uuid(),
      sku: faker.string.numeric(18),
      vendorItemId: faker.string.uuid(),
      vendorId: faker.string.uuid(),
      name: faker.lorem.sentence(3),
      image: faker.word.sample(),
      containerDescription: "355ml",
      packageDescription: "1x12",
      packageId: faker.helpers.maybe(() => faker.string.uuid(), {
        probability,
      }),
      variantDescription: faker.word.sample(),
      variantOrder: faker.helpers.maybe(() => faker.number.int(), {
        probability,
      }),
      quantityInfo,
      price: undefined,
      selectedQuantity: faker.number.int(),
    },
  ];

  const steppedDiscount = [
    {
      initialQuantity: faker.number.int(),
      finalQuantity: faker.number.int(),
      priceWithDiscount: faker.number.int(),
      discountAmount: faker.number.int(),
    },
  ];

  const priceMinMax = { min: 1, max: 100000 };
  const priceValueMock = faker.number.int(priceMinMax);
  const valuePerUnitMock = faker.helpers.maybe(() => priceValueMock / 10, {
    probability,
  });

  const promotion = {
    priceWithDiscount: priceValueMock / 2,
    priceWithDiscountPerUnit: valuePerUnitMock ? valuePerUnitMock / 2 : null,
    lowestPrice: faker.number.int(priceMinMax),
    highestPrice: faker.number.int(priceMinMax),
    steppedDiscounts: faker.helpers.maybe(() => steppedDiscount, {
      probability,
    }),
    freeGoods,
  };

  const price = {
    value: priceValueMock,
    type: faker.helpers.enumValue(PriceType),
    valuePerUnit: valuePerUnitMock,
    unitDescription: valuePerUnitMock ? "unit" : null,
    promotion: faker.helpers.maybe(() => promotion),
  };

  const variant = {
    platformId: faker.string.uuid(),
    sku: faker.string.numeric(18),
    vendorItemId: faker.string.uuid(),
    vendorId: faker.string.uuid(),
    name: faker.lorem.sentence({ min: 3, max: 30 }),
    image: faker.helpers.maybe(() => faker.image.urlPlaceholder(), {
      probability,
    }),
    containerDescription: faker.word.sample(),
    packageDescription: faker.word.sample(),
    packageId: faker.helpers.maybe(() => faker.string.uuid(), {
      probability,
    }),
    variantDescription: faker.word.sample(),
    variantOrder: faker.helpers.maybe(() => faker.number.int(), {
      probability,
    }),
    quantityInfo,
    price: price,
    selectedQuantity: faker.number.int(),
  };

  return <Product>{
    hasVariants: faker.datatype.boolean(),
    variants: [variant],
    ...override,
  };
}
