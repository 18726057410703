import { useIntl } from "react-intl";

import CheckoutButton from "./components/checkoutButton/CheckoutButton";
import LastOrderButton from "./components/showLastOrderButton/ShowLastOrderButton";
import * as Styled from "./ProductSelectionHeader.styles";

export default function ProductSelectionHeader() {
  const { formatMessage } = useIntl();

  return (
    <Styled.Container data-testid="product-selection-header">
      <Styled.Title size="H3" data-testid="product-selection-header-title">
        {formatMessage({
          id: "ProductSelection.TITLE",
        })}
      </Styled.Title>
      <Styled.ContainerButtons>
        <LastOrderButton />
        <CheckoutButton />
      </Styled.ContainerButtons>
    </Styled.Container>
  );
}
