import { ItemCard } from "@bees-grow-shared/business-components";
import { Select } from "@bees-grow-shared/components";
import { Product } from "@bees-grow-shared/services";

import { options } from "@/mocks/data/options";

import ProductSelectionHeader from "./components/productSelectionHeader/ProductSelectionHeader";
import ResponsiveGridWrapper from "./components/ResponsiveGridWrapper/ResponsiveGridWrapper";
import { useProductSelection } from "./hooks/useProductSelection";
import * as Styled from "./ProductSelection.styles";

export default function ProductSelection() {
  const { items, isLoading, addFakeProduct, removeFakeProduct } =
    useProductSelection();

  if (isLoading) {
    return <LoadingBehavior />;
  }

  return (
    <Styled.Container data-testid="product-selection">
      <ProductSelectionHeader />
      <Styled.ProductBoard data-testid="product-selection-board">
        <Styled.SearchProductInputContainer></Styled.SearchProductInputContainer>
        <Styled.SelectFilterContainer>
          <Select options={options} />
        </Styled.SelectFilterContainer>
        <ResponsiveGridWrapper>
          {items.map((item: Product) => (
            <ItemCard.Product item={item} key={item.variants[0].platformId} />
          ))}
        </ResponsiveGridWrapper>
      </Styled.ProductBoard>
      <button onClick={addFakeProduct}>Add fake product</button>
      <button onClick={removeFakeProduct}>Remove fake product</button>
    </Styled.Container>
  );
}

function LoadingBehavior() {
  return (
    <Styled.Container data-testid="product-selection-loading">
      <Styled.ProductBoardTitleLoad data-testid="product-selection-header-loading" />
      <Styled.ProductBoard data-testid="product-selection-board-loading">
        <ResponsiveGridWrapper isLoading />
      </Styled.ProductBoard>
    </Styled.Container>
  );
}
