export const options = [
  { key: 0, text: "Suggest Order" },
  { key: 1, text: "Promotions" },
  { key: 2, text: "Combos" },
  { key: 3, text: "Recommended" },
  {
    key: 4,
    text: "OptionWithCategorie1",
    categories: [
      {
        key: 4.1,
        text: "2nd level category 1",
        categories: [
          {
            key: 4.11,
            text: "3nd level category 1.1.1",
          },
          {
            key: 4.12,
            text: "2nd level category 1.1.2",
          },
          ,
          {
            key: 4.12,
            text: "2nd level category 1.1.2",
          },
          {
            key: 4.12,
            text: "2nd level category 1.1.2",
          },
          {
            key: 4.12,
            text: "2nd level category 1.1.2",
          },
          {
            key: 4.12,
            text: "2nd level category 1.1.2",
          },
          {
            key: 4.12,
            text: "2nd level category 1.1.2",
          },
          {
            key: 4.12,
            text: "2nd level category 1.1.2",
          },
          {
            key: 4.12,
            text: "2nd level category 1.1.2",
          },
          {
            key: 4.12,
            text: "2nd level category 1.1.2",
          },
          {
            key: 4.12,
            text: "2nd level category 1.1.2",
          },
          {
            key: 4.12,
            text: "2nd level category 1.1.2",
          },
        ],
      },
      { key: 4.2, text: "2nd level category 1.1" },
      { key: 4.2, text: "2nd level category 1.1" },

      { key: 4.2, text: "2nd level category 1.1" },

      { key: 4.2, text: "2nd level category 1.1" },

      { key: 4.2, text: "2nd level category 1.1" },

      { key: 4.2, text: "2nd level category 1.1" },

      { key: 4.2, text: "2nd level category 1.1" },

      { key: 4.2, text: "2nd level category 1.1" },

      { key: 4.2, text: "2nd level category 1.1" },

      { key: 4.2, text: "2nd level category 1.1" },

      { key: 4.2, text: "2nd level category 1.1" },

      { key: 4.2, text: "2nd level category 1.1" },

      { key: 4.2, text: "2nd level category 1.1" },

      { key: 4.2, text: "2nd level category 1.1" },

      { key: 4.2, text: "2nd level category 1.222222" },
    ],
  },
  {
    key: 5,
    text: "OptionWithCategorie2",
    categories: [
      {
        key: 5.1,
        text: "2nd level category 2",
        categories: [
          {
            key: 5.11,
            text: "3nd level category 2.1.1",
          },
          {
            key: 5.12,
            text: "2nd level category 2.1.2",
          },
        ],
      },
      { key: 5.2, text: "2nd level category 2.1" },
    ],
  },
];
