import { tokens } from "@bees-grow-shared/theme";
import { Quantifier, SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

import { ResponsiveCard } from "../../ResponsiveGridWrapper.styles";

export const Container = styled(ResponsiveCard)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 24.625rem;
  box-sizing: border-box;
`;

export const HeaderSection = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const ImageSkeleton = styled(SkeletonLoader)`
  width: 6rem;
  height: 6rem;
  box-sizing: border-box;
`;

export const HeaderInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const TitleSkeleton = styled(SkeletonLoader)`
  width: 11.938rem;
  height: ${tokens.measure["font-size"]["fontsize-3"]};
`;

export const TitleSecondLineSkeleton = styled(SkeletonLoader)`
  width: 9.688rem;
  height: ${tokens.measure["font-size"]["fontsize-3"]};
`;

export const DescriptionSection = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const SkuSkeleton = styled(SkeletonLoader)`
  width: 4.125rem;
  height: ${tokens.measure["font-size"]["fontsize-2"]};
`;

export const BulletDivider = styled.div`
  width: 2px;
  height: 2px;
  border-radius: 100%;
  background-color: ${tokens.color.component.bg.divider.default};
  align-self: center;
`;

export const VolumeterSkeleton = styled(SkeletonLoader)`
  width: 2.313rem;
  height: ${tokens.measure["font-size"]["fontsize-2"]};
`;

export const PackageQuantitySkeleton = styled(SkeletonLoader)`
  width: 2.25rem;
  height: ${tokens.measure["font-size"]["fontsize-2"]};
`;

export const UnitSkeleton = styled(SkeletonLoader)`
  width: 8.188rem;
  height: ${tokens.measure["font-size"]["fontsize-2"]};
  margin-top: 1rem;
  margin-bottom: 0.25rem;
`;

export const BodySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.25rem 0;
`;

export const PriceSkeleton = styled(SkeletonLoader)`
  width: 8.688rem;
  height: ${tokens.measure["font-size"]["fontsize-3"]};
`;

export const InfoSkeleton = styled(SkeletonLoader)`
  width: 6.625rem;
  height: ${tokens.measure["font-size"]["fontsize-3"]};
`;

export const QuantitySection = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const QuantityInfo = styled.div`
  width: 7.125rem;
`;

export const QuantifierInput = styled(Quantifier)`
  visibility: hidden;
`;

export const EmptyButton = styled.div`
  width: 97px;
  height: 32px;
  border-radius: 48px;
  border: 1px solid #14141414;
`;
