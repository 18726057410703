import { useEffect, useState } from "react";

import { makeFakeProduct } from "@/mocks/services/makeFakeProduct";

export function useProductSelection() {
  const [items, setItems] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, [isLoading]);

  const addFakeProduct = () => {
    setItems([...items, makeFakeProduct()]);
  };

  const removeFakeProduct = () => {
    setItems(items.slice(0, items.length - 1));
  };

  return { items, isLoading, addFakeProduct, removeFakeProduct };
}
