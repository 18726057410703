import { tokens } from "@bees-grow-shared/theme";
import { Heading, SkeletonLoader } from "@hexa-ui/components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.measure.space["spacing-4"]};
  margin: 2rem 0 0;
  min-width: 55.5rem;
  max-width: 113rem;
`;

export const ProductBoardTitle = styled(Heading)`
  margin: 0;
  font-family: Barlow;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  text-align: left;
`;

export const ProductBoard = styled.div`
  background-color: ${tokens.color.foundation.bg["primary-clear"]};
  padding: 1.5rem;
  border-radius: ${tokens.measure["border-radius"]["borderradius-4"]};
  box-shadow: ${tokens.shadow["elevation-1"]};
  overflow: hidden;
  width: auto;
  min-height: 973px;
`;

export const ProductBoardTitleLoad = styled(SkeletonLoader)`
  height: ${tokens.measure["line-height"]["lineheight-7"]};
  width: 7.5rem;
`;

export const SearchProductInputContainer = styled.div`
  height: 54px;
  border-bottom: 1px solid ${tokens.color.component.bg.dropdown.disabled};
`;

export const SelectFilterContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
  height: 56px;
`;
